

body {
  background-color: #14232a;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

.title {
  font-family: "Poppins", sans-serif;
  font-weight: 700; /* Adjust as needed */
}

/* Adjust other elements accordingly */
.strong-text {
  font-family: "Poppins", sans-serif;
  font-weight: 700; /* For stronger emphasis */
}


.img-container {
  /* border-radius: 10.14px 10.14px 10.14px 253.51px; */
  /* border-radius: 3.86px 3.86px 3.86px 96.53px; */
  @apply rounded-tl-[3.86px] md:rounded-tl-[10.14px] ;
  @apply rounded-tr-[3.86px] md:rounded-tr-[10.14px] ;
  @apply rounded-b-[3.86px] md:rounded-b-[10.14px] ;
  @apply rounded-bl-[96.53px] md:rounded-bl-[253.51px] ;
  
}

.promotion-carousel {
  position: relative;
  width: 100%;
  height: auto;
  /* overflow: hidden; */
  /* min-width: 900px; */
  max-height: max-content;
  margin-bottom: 20px;
  border-radius: 15px;

  @apply min-w-full md:min-w-[900px];
}
/* prmoo-title */
.title {
  /* word-break: break-all; */
  @apply md:w-[25ch] font-semibold text-[44px] md:text-[100px] text-white md:tracking-[-5px] md:w-[40vw] md:max-w-[800px] relative left-2 md:-right-20 leading-[44px] md:leading-[8rem] capitalize;
  /* overflow-wrap: break-word; */
}

.promotion-carousel-item {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  opacity: 0;
  z-index: -1;
  transition: opacity 1s ease-in-out;
}

.promotion-carousel-item.active {
  opacity: 1;
  z-index: 40;
}

.slider-button:hover > svg > rect {
  fill: #192d36 !important;
}
.skeleton-loader {
  width: 100%;
  height: 650px; /* Adjust the height to match your image container */
  background-color: #eee;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #ddd;
  }
  100% {
    background-color: #eee;
  }
}

.signUpModal {
  position: fixed; /* Position the modal in the center of the screen */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 99999; /* Ensure the modal appears above other elements */
}

.signUpModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Lower than the modal itself */
}
.delete-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* width: 100%;
  height: 100%; */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9999; /* Lower than the modal itself */
}
.fixed-sidebar {
  position: fixed;
  top: 0; /* Adjust this value as needed */
  left: 0; /* Adjust this value as needed */
  width: 100%; /* Or set a specific width */
  z-index: 1000; /* To ensure it stays above other content */
}

/* Style the scrollbar track */
.customize_scroll::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Handle */
.customize_scroll::-webkit-scrollbar-thumb {
  background: #14232a; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the scrollbar thumb */
}

/* Handle on hover */
.customize_scroll::-webkit-scrollbar-thumb:hover {
  background: #fff; /* Darker color when hovering over the scrollbar thumb */
}

.customize_scroll_white::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

/* Handle */

.customize_scroll_white::-webkit-scrollbar-thumb {
  background: #fff; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the scrollbar thumb */
}

/* Handle on hover */
.customize_scroll_white::-webkit-scrollbar-thumb:hover {
  background: #fff; /* Darker color when hovering over the scrollbar thumb */
}

/* animation */
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.show {
  opacity: 1;
}
/* checkbox css */
.checkbox-wrapper-4 * {
  box-sizing: border-box;
}
.checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  /* overflow: hidden; */
  transition: all 0.2s ease;
  display: inline-block;
}
.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}
.checkbox-wrapper-4 .cbx:hover {
  background: rgba(0, 119, 255, 0.06);
}
.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}
.checkbox-wrapper-4 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}
.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: #d2973d;
}
.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: #d2973d;
  border-color: #d2973d;
  animation: wave-4 0.4s ease;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.menu_preview > img{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 640px) {
  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: inline-block;
  }
}
@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

/* Google Fonts - Poppins */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"); */

.border-container {
  position: relative;
  height: 450px;
  width: 350px;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
}
.border-container.active {
  background-color: #000;
}
.border-container::before {
  content: "";
  position: absolute;
  height: 650px;
  width: 650px;
  background-image: conic-gradient(transparent, transparent, transparent, #fff);
}
.border-container.active:before {
  animation: rotate 4s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.border-container .overlay {
  position: absolute;
  height: 440px;
  width: 340px;
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  border-radius: 12px;
  background-color: #131221;
}

/*  */
.loader-svg {
  width: 155px;
  height: 155px;
  position: relative;
  top: -35px;
  left: -5px;
  transform: rotate(-90deg);
}

.circle {
  stroke-dasharray: 377;
  stroke-dashoffset: 377;
  transition: all 1s ease;
}

.svg_1:hover .circle {
  stroke-dashoffset: 0;
}

/* App.css */
.border-progress-bar {
  width: 200px;
  height: 50px;
  background: black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
  transition: 0.5s;
}

.border-progress-bar:before,
.border-progress-bar:after {
  content: "";
  position: absolute;
  border: 2px solid white;
  animation: progress-animation 3s infinite alternate;
}

.border-progress-bar:before {
  left: 0;
  top: 0;
  border-top: 2px solid white;
  border-right: 2px solid white;
}

.border-progress-bar:after {
  right: 0;
  bottom: 0;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
}

.border-progress-bar:hover {
  background: rgb(19, 17, 17);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6);
}

@keyframes progress-animation {
  0% {
    width: 0;
    height: 0;
  }
  25% {
    width: 200px;
    height: 0;
  }
  50% {
    width: 200px;
    height: 48px;
  }
  100% {
    width: 200px;
    height: 48px;
  }
}

.div-progress {
  /* margin: auto;
  width: 200px;
  height: 160px; */
  /* border-radius: 20px; */
  position: relative;
  /* border: 1px solid #ddd; */
  transition: background-image 0.1s linear;
  /* background-color: white; */
}

.div-progress::after {
  content: "";
  display: block;
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  background-color: transparent;
  /* background-image: conic-gradient(
    orange,
    orange 80%,
    transparent 80%
  ); */
  z-index: -1;
  border-radius: 21.5px;
}


/* topOffer Bounce */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.animate-bounce {
  animation: bounce 2s infinite;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: #FBBC05;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
