@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  @apply max-w-[1360px] mx-auto;
}
.active-input {
  @apply bg-bg-main text-white;
}
/* Happyhour selected */
.selectedHappyHourOffer {
  @apply bg-bg-main border-[#FFFFFF];
}
.orange-btn {
  @apply bg-main-orange text-[#fff] hover:bg-main-orange-100 transition-colors delay-75;
}
.signin-action-btn {
  @apply hover:bg-main-orange-300 transition-colors delay-75 font-semibold text-xl text-bg-main bg-main-orange-200 w-full h-14 max-w-[408px] mx-auto rounded-full;
}

/* stepper form indicator */
.stepper-line {
  @apply absolute -right-[48px] w-12 h-[2px];
}
.stepper-line::before {
  content: "";
  width: 12px;
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  @apply bg-bg-main;
}

.stepper-line-before-none::before {
  display: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #14232a inset !important;
  -webkit-text-fill-color: white !important;
}

input:-moz-ui-autofill,
input:-moz-ui-autofill:hover,
input:-moz-ui-autofill:focus,
input:-moz-ui-autofill:active {
  box-shadow: 0 0 0px 1000px #14232a inset !important;
  caret-color: transparent !important;
}

.alert-box {
  @apply cursor-pointer bg-bg-main rounded-md h-16 flex justify-center items-center m-10 mt-0 hover:opacity-100 border z-50 w-16 transition-all delay-150 opacity-25;
}

.alert-content {
  @apply gap-2 items-center justify-between mx-auto relative hidden invisible;
}

.alert-box:hover {
  width: 258px;
  @apply border-main-orange  border-2 opacity-100;
}

.alert-box:hover .alert-img {
  @apply hidden;
}

.alert-box:hover .alert-content {
  @apply flex visible;
}

.offer-details-button:hover #popover-user-profile {
  @apply opacity-100 visible;
}
/* footer */

.boosted-happy-hour {
  @apply border-2 border-main-orange-50 text-white rounded-xl h-24 px-6 flex items-center gap-5 hover:bg-main-orange-50 hover:text-bg-main transition-colors delay-75 cursor-pointer max-w-60;
}
.boosted-happy-hour > svg {
  width: 35px;
}
.boosted-happy-hour:hover > svg path {
  fill: #000 !important;
}

.establishment-img-container:hover > .slider-btn {
  transition: ease 0.3s;
}
.establishment-img-container:hover > .slider-btn {
  @apply opacity-100 visible;
}
.carousel-slider:hover > button > .slider-btn {
  transition: ease 0.3s;
}
.carousel-slider:hover > button > .slider-btn {
  @apply opacity-100 visible;
}

/* carousel */
.slider-wrapper {
  max-width: 500px;
  margin: unset !important;
}
.control-dots {
  max-width: 500px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  @apply !my-0;
}
.carousel .control-dots .dot {
  @apply !mx-[5px] md:!w-[6px] md:!h-[6px]  md:!w-[10px] md:!h-[10px] xl:!w-[8px] xl:!h-[8px] ;
}
.carousel .control-dots .dot .selected {
  @apply shadow-xl;
}
.carousel .carousel-slider {
  min-width: unset !important;
  max-width: 500px;
}

/* inputs with icons */
.input-w-icon > svg {
  @apply absolute right-4 top-4;
}

/* mobile */
@media (max-width: 500px) {
  .header-mob {
    background: rgba(255, 255, 255, 0.1);
    max-width: 86%;
    padding: 10px;
    margin: auto;
  }
}


/* Add this to your global CSS file */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
